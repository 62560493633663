import React, { useLayoutEffect } from "react"
import styled, { ThemeProvider } from "styled-components"
import SEO from "src/components/seo"
import { darkTheme } from "src/utils/themes"
import { NewLandingPageHeader } from "src/components/Header"
import OfferingBanner from "src/components/offerings/mobile/OfferingBanner"
import IntroSection from "src/components/offerings/mobile/IntroSection"
import ProductDevelopment from "src/components/offerings/mobile/ProductDevelopment"
import Security from "src/components/offerings/mobile/Security"
import DueDiligence from "src/components/offerings/mobile/DueDiligence"
import Slider from "src/components/offerings/desktop/Slider"
import Services from "src/components/offerings/desktop/Services"
import { LightFooter } from "src/components/Footer"
import DesktopSecurity from "src/components/offerings/desktop/Security"
import DesktopDiligence from "src/components/offerings/desktop/Diligence"
import MobileNavigation from "src/components/offerings/mobile/MobileNavigation"

const MobileContainer = styled.div`
  display: none;
  @media screen and (max-width: 1100px) {
    display: block;
  }
`

const DesktopContainer = styled.div`
  display: block;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`

const useScrollTo = id => {
  useLayoutEffect(() => {
    if (id) {
      const el = document.getElementById(id)
      const top = window.scrollY + el.getBoundingClientRect().top
      window.scrollTo({ top, behavior: "smooth" })
    }
  }, [id])
}

const SecurityOfferings = ({ location }) => {
  useScrollTo(location?.state?.targetFragment)
  return (
    <ThemeProvider theme={darkTheme}>
      <SEO
        title="A global remote-native software engineering guild"
        description="Building and scaling the world’s most ambitious startups"
        location={location}
        image="seo_main.png"
      />
      <NewLandingPageHeader />
      <MobileContainer>
        <Slider />
        <MobileNavigation id={"security"} />
        {/* <IntroSection /> */}
        <Security id="security" />
      </MobileContainer>
      <DesktopContainer>
        <Slider />
        {/* <IntroSection /> */}
        <DesktopSecurity />
      </DesktopContainer>
      <LightFooter />
    </ThemeProvider>
  )
}

export default SecurityOfferings
